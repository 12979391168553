<template>
	<div>
		<div class="box">
            <div class="row align-items-center">
                <div class="col">
                    <h3 class="mb-0">{{ $t('invoice.stats_invoices_bons') }}</h3>
                </div>
                <div class="col-auto">
                    <b-button v-b-toggle.invoiceStat variant="primary" class="mb-0">{{ $t('invoice.see_stats') }} <font-awesome-icon :icon="['fal', 'angle-down']" /><font-awesome-icon :icon="['fal', 'angle-up']" /></b-button>
                </div>
            </div>
            <b-collapse v-if="stats_loaded" id="invoiceStat">
                <div class="row pt-4">
                   <b-table striped hover :items="stat" :fields="fields_stat"></b-table>
                </div>
            </b-collapse>
            <div v-else>
                <LoadingSpinner />
            </div>
        </div>
		<div class="row">
		    <div class="col-auto ml-auto d-flex">
		        <a href="" v-if="options.type == 'Contract'" class="btn ml-3 mb-4 btn-secondary" @click.prevent="setupContractInvoices(options.id)">
		            <i><font-awesome-icon :icon="['far', 'file-pdf']" /></i> {{ $t('monte.factures') }} <i><font-awesome-icon :icon="['fas', 'eye']" /></i>
		        </a>
		    </div>
		</div>
		<div class="box">
			<CustomTable
				id_table="bons"
				:items="bons"
				:busy.sync="table_busy"
				primaryKey="invoicedetails_id"
				:hide_if_empty="true"
				:hrefsRoutes="config_table_hrefs"
				:rawColumns="rawColumnNames"
				:externSlotColumns="extern_slot_columns"
				ref="bonTable"
			>
                <template v-slot:[`custom-slot-cell(invoicedetails_tiershorsepartpart)`]="{ data }">
					{{ data.invoicedetails_tiershorsepartpart }}%
				</template>
            </CustomTable>
		</div>

		<template v-if="has_doublons">
			<div class="row">
			    <div class="col">
					<h2>{{ $t("invoice.titre_bons_doublons") }}</h2>
			    </div>
			    <div class="col-auto ml-auto d-flex">
			        <a href="" v-if="options.type == 'Contract'" class="btn ml-3 mb-4 btn-secondary" @click.prevent="setupContractInvoices(options.id)">
			            <i><font-awesome-icon :icon="['far', 'file-pdf']" /></i> {{ $t('monte.factures') }} <i><font-awesome-icon :icon="['fas', 'eye']" /></i>
			        </a>
			    </div>
			</div>
			<div class="box">
				<CustomTable
					id_table="bons_doublons"
					:items="bons_doublons"
					:busy.sync="table_busy"
					primaryKey="invoicedetails_id"
					:hide_if_empty="true"
					:hrefsRoutes="config_table_hrefs"
					:rawColumns="rawColumnNames"
					ref="bonDoublonsTable"
				/>
			</div>
		</template>

		<ModalBons ref="modal" :version="1" :bons="bons"/>

		<b-modal ref="modalLigneVierge" no-close-on-backdrop hide-footer>
			<template v-slot:modal-title>

				<template v-if="ajouter">
					{{ $t("action.ajout_ligne_vierge") }}
				</template>

				<template v-if="modifier">
					{{ $t("action.modifier_ligne_vierge") }}
				</template>
			</template>

			<div v-if="!modal_loaded">
				<LoadingSpinner class="col-12" />
			</div>

			<div v-if="modal_loaded">

				<LigneVierge ref="ligne" :invoicedetails_id="invoicedetails_id" :bons="bons" :processing.sync="processing" :preselect_infos="preselect_infos" :ready.sync="ready" :options="options"></LigneVierge>

				<div class="col-8 m-auto">
					<template v-if="ready && invoicedetails_id">
						<b-button block pill variant="primary" @click="onSubmit"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("action.modifier") }}</b-button>
						<b-button block pill variant="primary" @click="onSubmit(true)"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("action.modifier_et_nouveau") }}</b-button>
					</template>
					<template v-else-if="ready">
						<b-button block pill variant="primary" @click="onSubmit"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("action.ajouter") }}</b-button>
						<b-button block pill variant="primary" @click="onSubmit(true)"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("action.ajouter_et_nouveau") }}</b-button>
					</template>
				</div>
			</div>
		</b-modal>
		<b-modal ref="modalCancel" hide-footer>
			<template v-slot:modal-title>
				{{ $t("modal.invoice.bons.suppression") }}
			</template>

			<p class="text-center">{{ $t("modal.invoice.bons.confirmation_suppression") }}</p>

			<div class="row">
				<div class="col-12 d-flex justify-content-center">
					<b-button variant="primary" @click="onCancel(true)" v-if="is_cancelation">{{ $t("action.repasser_a_valider") }}</b-button>
					<b-button variant="primary" class="mx-2" @click="onCancel">{{ $t("action.abandonner") }}</b-button>
					<b-button variant="secondary" @click="$refs.modalCancel.hide()">{{ $t("global.close") }}</b-button>
				</div>
			</div>
		</b-modal>
		<b-modal ref="modalChangeTiers" hide-footer>
			<template v-slot:modal-title>
				{{ $t("action.change_tiers") }}
			</template>

			<div v-if="!modal_loaded">
				<LoadingSpinner class="col-12" />
			</div>

			<div v-if="modal_loaded">

				<form @submit.prevent="checkFormChangeTiers" class="mb-4">
					<div class="row">
						<div class="col-12" v-if="form_message !== ''">
							<ErrorAlert :messageI18n="form_message" />
						</div>

						<div class="col-12">
							<div class="mb-2">
								<label class="mb-1" for="tiers_id">{{ $t('tiers.tiers') }} *</label>
								<e-select
									v-model="change_tiers"
									track-by="id"
									label="rs"
									:placeholder="labelTitleTiers"
									:selectedLabel="selectedLabel"
									:options="tiers"
									:searchable="true"
									:allow-empty="false"
									:loading="isLoadingTiers"
									:show-labels="false"
									:class="{ 'is-invalid': errors && errors.indexOf('Erreur no tiers') > -1 }"
								>
									<template slot="singleLabel" slot-scope="{ option }">{{ option.rs }}</template>
									<template slot="noOptions">{{ $t('global.list_empty') }}</template>
								</e-select>

							</div>
						</div>
					</div>
				</form>

				<div class="col-8 m-auto">
					<b-button v-if="ready && invoicedetails_id" block pill variant="primary" @click="onSubmitChangeTiers"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("action.modifier") }}</b-button>
				</div>
			</div>
		</b-modal>

	</div>
</template>


<script type="text/javascript">
	import Vue from 'vue'
	import { EventBus } from 'EventBus'

	import Common from '@/assets/js/common.js'
	import TableAction from "@/mixins/TableAction.js"
	import Invoice from "@/mixins/Invoice.js"
	import Tiers from "@/mixins/Tiers.js"
	import Navigation from "@/mixins/Navigation.js"
	import Config from "@/mixins/Config.js"
	import Model from "@/mixins/Model.js"
	import Accounting from "@/mixins/Accounting.js"
	import Contract from "@/mixins/Contract.js"

	import ShutterContract from "@/mixins/shutters-manager/Contract.js"
	import Shutter from '@/mixins/Shutter.js'

	import ContractCleaner from '@/assets/js/cache/cleaners/ContractCleaner'
	import _uniq from 'lodash/uniq'
	import ESelect from '../../../groomy-root/src/components/Utils/ESelect.vue'

	export default {
		name: "list",
		mixins: [Config, TableAction, Invoice, Navigation, Tiers, ShutterContract, Shutter, Model, Accounting, Contract],
		props: {
			options: {
				type: Object,
				default: () => ({
					type: 'Invoice',
					id: null,
				})
			}
		},
		data () {
			return {
				bons: null,
				bons_doublons: null,
				table_busy: true,
				first_invoice: false,
				modal_loaded: false,
				invoicedetails_id: null,
				date: new Date(),
				today: new Date(),
				is_cancelation: true,
				form_message: '',
				params_to_send : [],
				errors : [],
				config_duedate : [],
				amount : {},
				processing: false,
				ready: false,
				entity_id: null,
				horse_id: null,
				tiers_id: null,
				ajouter: true,
				modifier: false,
				has_doublons: false,
				copy_duedate: null,
				dedoublonner: false,
				cancel_invoicedetails_ids: null,
				preselect_infos: {},
				change_tiers: null,
				use_rib_stallions: true,
				ref_client: null,
				dossier_interne: null,
				renew: {
					"code": 'null',
					"label": this.getTrad("invoice.renew.no")
				},
				renew_options: [
					{
						"code": 'null',
						"label": this.getTrad("invoice.renew.no")
					},
					{
						"code": 'mensuel',
						"label": this.getTrad("invoice.renew.mensuel")
					},
					{
						"code": 'annuel',
						"label": this.getTrad("invoice.renew.annuel")
					},
				],
				ribs: [],
				model: [],
				tiers: [],
				isLoadingTiers: false,
				labelTitleTiers: this.getTrad("tiers.rechercher"),
				extern_slot_columns: ['invoicedetails_tiershorsepartpart'],
				selectedLabel: this.getTrad("global.selected_label"),
				cache: {
					model: []
				},
				recalcul_duedate: true,
				rawColumnNames: ['horse.horse_nom','invoicedetails_ht'],
				config_table_hrefs: {
					'horse.horse_nom': {
						routeUniqueName: 'horseFiche',
						routeName: 'horseFiche',
						params: {
							horse_id: 'horse.horse_id'
						}
					},
					'tiers.tiers_rs': {
						routeUniqueName: 'tiersFiche',
						routeName: 'tiersFiche',
						params: {
							tiers_id: 'tiers.tiers_id'
						}
					}
				},
				events_tab: {
					'TableAction::goToAddInvoice': (params) => {
						this.addInvoiceForm(params.invoicedetails_id)
					},
					'TableAction::goToAddInvoiceDetail': (params) => {
						this.addInvoiceDetailForm(params.invoicedetails_id)
					},
					'TableAction::goToCancelInvoiceDetail': (params) => {
						this.is_cancelation = true
						this.cancel_invoicedetails_ids = params.invoicedetails_id
						this.dedoublonner = false
						this.$refs.modalCancel.show()
						// this.cancelInvoiceDetail(params.invoicedetails_id)
					},
					'TableAction::goToDedoublonnerInvoiceDetail': (params) => {
						this.is_cancelation = false
						this.cancel_invoicedetails_ids = params.invoicedetails_id
						this.dedoublonner = true
						this.$refs.modalCancel.show()
						// this.cancelInvoiceDetail(params.invoicedetails_id, true)
					},
					'TableAction::goToPreviewInvoice': (params) => {
						this.previewInvoiceDetail(params.invoicedetails_id)
					},
					'TableAction::goToChangeTiersBaf': (params) => {
						this.changeTiersBaf(params)
					},
					'TableAction::goToReorganizeInvoiceDetails': (params) => {
						let bons = this.bons.filter(bon => params.indexOf(bon.invoicedetails_id) > -1 )
						let grouped_bons = bons.map((elem) => {
							return elem.invoicedetails_author + "_" + elem.invoicedetails_tiers
						})
						grouped_bons = _uniq(grouped_bons)
						if(grouped_bons.length > 1)
						{
							this.failureToast('toast.reorganize_only_one_tiers')
							return false
						}

						this.$router.push({ name: 'invoiceDetailOrder', params: {invoicedetails_ids: params.join(',')}})
					},
					'TableAction::goToCopyInvoiceDetail': (params) => {
						this.copyInvoiceDetail(params)
					},
					'TableAction::goToRecheckVat': (params) => {
						this.recheckVat(params)
					}
				},
				stats_loaded: true,
                stat: [],
                fields_stat: [
					{key: 'tiers', label: this.getTrad('monte.reservation.client'), sortable: true},
                    {key: 'total_ht', label: this.getTrad('invoice.stat_total_ht'), sortable: true},
                ],
			}
		},
		mounted() {
			this.init_component()
		},
		methods: {
			async init_component() {
                this.loadBons().then(async () => {
					this.compareLoadingMethods()
				})
			},
			async loadBons() {
				this.table_busy = true
				this.has_doublons = false
                const bons = await this.loadAllBons(this.options)
                this.bons = await this.formatBons(bons)

                const bons_doublons = await this.loadAllBons({
                	type: 'Doublons',
                	id: null
                })
                this.bons_doublons = await this.formatBons(bons_doublons)
                if(this.bons_doublons.length > 0)
                {
                	this.has_doublons = true
                }

				this.loadStats()
                
                this.table_busy = false
            },
            async formatBons(bons) {
				if(!bons) {
					return []
				}

				for (var i = 0; i < bons.length; i++) {
					bons[i].invoicedetails_ht = await this.priceFormat(bons[i].invoicedetails_ht, bons[i].author.tiers_currency,true)
					bons[i].invoicedetails_htunit = await this.priceFormat(bons[i].invoicedetails_htunit, bons[i].author.tiers_currency,true)

					if(bons[i].horse != null)
					{
						bons[i].href = "horseFiche"
						bons[i].params = { horse_id: bons[i].horse.horse_id }
					}

					if(bons[i].invoicedetails_horsepensionperiode != null)
					{
						bons[i].articles.articles_label += " - " + bons[i].invoicedetails_label
					}

					bons[i].group_by_author_tiers = bons[i].author.tiers_rs + " / " + bons[i].tiers.tiers_rs + " (" + this.getTrad("compta.form." + bons[i].vat_code_tiers.toLowerCase()) + ")"
					bons[i].customClasses={
                        invoicedetails_ht:"text-right"
					}
				}

				return bons
			},
			async addInvoiceForm(invoicedetails_ids)
			{
				await this.$refs['modal'].openModal(invoicedetails_ids)
			},

			addInvoiceDetailForm(invoicedetails_id) {
				this.modal_loaded = false
				this.preselect_infos = {}
				if(invoicedetails_id == "")
				{
					this.modifier = false
					this.ajouter = true
				}
				else
				{
					this.modifier = true
					this.ajouter = false
				}
				this.invoicedetails_id = invoicedetails_id

				this.$refs["modalLigneVierge"].show()

				this.modal_loaded = true
			},

			copyInvoiceDetail(invoicedetails) {

				this.modal_loaded = false

				this.modifier = false
				this.ajouter = true
				this.preselect_infos = {
					entity: invoicedetails.author,
					tiers: invoicedetails.tiers,
					horse: invoicedetails.horse,
					article: invoicedetails.articles,
					invoice_details: invoicedetails,
				}

				this.$refs["modalLigneVierge"].show()

				this.modal_loaded = true
			},

			async previewInvoiceDetail(invoicedetails_ids) {
				this.modal_loaded = false

				const splited_invoice_details = invoicedetails_ids.split(",")
				const all_bons = this.bons
				const infos = []
				const tiers = []
				const authors = []
				for (var i = 0; i < splited_invoice_details.length; i++) {

					const bons_selected = this.bons.filter(all_bons => all_bons.invoicedetails_id == splited_invoice_details[i])[0]

					if(authors.indexOf(bons_selected.invoicedetails_author) === -1 || tiers.indexOf(bons_selected.invoicedetails_tiers) === -1)
					{
						infos.push({
							"author_id": bons_selected.invoicedetails_author,
							"tiers_id": bons_selected.invoicedetails_tiers,
						})

						tiers.push(bons_selected.invoicedetails_tiers)
						authors.push(bons_selected.invoicedetails_author)
					}
				}

				let params_to_send = {
					"invoices": infos,
					"invoicedetails_ids": invoicedetails_ids
				}

				let res = await this.previewInvoice(params_to_send)

				if(res) {
					EventBus.$emit('TableAction::stopSpin')
				}

				this.modal_loaded = true
			},

			closeFirstInvoiceModal: async function(){
				this.$refs['confirmation_first_invoice'].hide()
			},

			async onCancel(go_valider) {
				this.$refs.modalCancel.hide();
				if(go_valider === true) {
					await this.cancelInvoiceDetail(this.cancel_invoicedetails_ids, this.dedoublonner, true)
				}
				else {
					await this.cancelInvoiceDetail(this.cancel_invoicedetails_ids, this.dedoublonner, false)
				}
			},

			async cancelInvoiceDetail(invoicedetails_ids, dedoublonner, send_a_valider){
				let params_to_send = {
					invoicedetails_ids: invoicedetails_ids,
					send_a_valider: send_a_valider
				}

				let result = null
				if(dedoublonner != undefined && dedoublonner === true) {
					result = await this.dedoublonnerInvoiceDetails(params_to_send)
				}
				else{
					result = await this.deleteInvoiceDetails(params_to_send)
				}


				if(result != null) {
					this.$refs.bonTable.resetCachedItems()

					if(this.$refs.bonDoublonsTable) {
						this.$refs.bonDoublonsTable.resetCachedItems()
					}

					this.successToast()
					this.loadBons()

					if(this.options.type == "Contract"){
						//Je force la synchro pour reload la table contract_avenant_article et ensuite je vais recharger les "a valider"
						EventBus.$emit('TableAction::waitingTableAValider', true)
						await this.$sync.force(true, true)
	                    ContractCleaner.inst().onMutation([this.options.id], ['articles'])
	                    this.updateContract([this.options.id])
	                    EventBus.$emit('TableAvenantArticle::ReloadArticle', true)
						EventBus.$emit('TableAction::reloadAValider', true)
					}
				}
			},

			async recheckVat(params){
				let result = await this.getRecheckVat(params.invoicedetails_id.split(','))

				if(result != null) {
					this.$refs.bonTable.resetCachedItems()
					this.successToast()
					this.loadBons()
				}
			},

			async onSubmit(open_with_copy = false) {
				this.processing = true
				let invoice_details = await this.$refs.ligne.checkForm()

				if(invoice_details !== false)
				{
					this.$refs["modalLigneVierge"].hide()
					this.successToast()
					this.loadBons()
					if(this.options.type == "Contract") {
                    	await this.$sync.force(true)
	                	ContractCleaner.inst().onMutation([this.options.id], ['articles'])
	                	this.updateContract([this.options.id])
					}

					if(open_with_copy === true) {
						this.invoicedetails_id = null
						//Dans le cas d'ajout de ligne, on me retourne un tableau de ligne, du coup je prend la 1ere
						if(Array.isArray(invoice_details.retour)) {
							this.copyInvoiceDetail(invoice_details.retour[0])
						}
						else {
							this.copyInvoiceDetail(invoice_details.retour)
						}
					}
				}
				this.processing = false
				this.ready = true
			},

			pension_checkall(item){
				if(item.length)
				{
					if(item[0].invoicedetails_horsepensionperiode != null)
					{
						//Je vais regarder toutes les pensions id qui vont ensembles sur le même cheval et même tiers pour toutes les facturer en même temps
						let invoicedetails_horsepensionperiode = item[0].invoicedetails_horsepensionperiode
						
						let all_same_pensions = this.bons.filter(pension => pension.invoicedetails_horsepensionperiode == invoicedetails_horsepensionperiode)

						this.$refs.bonTable.manual_check(all_same_pensions)
					}
				}
				
			},

			pension_decheckall(item){
				if(item.length)
				{
					if(item[0].invoicedetails_horsepensionperiode != null)
					{
						let invoicedetails_horsepensionperiode = item[0].invoicedetails_horsepensionperiode

						let all_same_pensions = this.bons.filter(pension => pension.invoicedetails_horsepensionperiode == invoicedetails_horsepensionperiode) 
						this.$refs.bonTable.manual_decheck(all_same_pensions)
					}
				}
				
			},

			async changeTiersBaf(invoicedetails_ids){
				this.$refs.modalChangeTiers.show()
				this.ready = false
				this.modal_loaded = false
				this.isLoadingTiers = true
				this.invoicedetails_id = invoicedetails_ids

				let tiers = await this.loadTiers()
				this.tiers = []
                for (let i = 0; i < tiers.length; i++) {
					this.tiers.push({
						id: tiers[i].tiers_id,
						rs: tiers[i].tiers_formatted,
					})
				}

				this.ready = true
				this.modal_loaded = true
				this.isLoadingTiers = false
			},

			async onSubmitChangeTiers(){

				if(!this.processing)
				{
					this.processing = true
					this.errors = []
					if(!this.invoicedetails_id)
					{
						this.errors.push("lignes")
					}

					if(!this.change_tiers)
					{
						this.errors.push("Erreur no tiers")
					}
					if(this.errors.length > 0)
					{
						this.processing = false
						return false
					}

					let result = null
					result = await this.setChangeTiers(this.invoicedetails_id, this.change_tiers.id)
					if(result != null)
					{
						this.successToast();
						this.$refs.modalChangeTiers.hide()
						this.processing = false
						
						await this.loadBons();
					}
					else{
						this.processing = false
						this.failureToast();
					}
					this.change_tiers = null
				}

			},

			async loadStats() {
                this.stats_loaded = false
                this.stat = []

                if(this.bons.length === 0) {
                    this.stats_loaded = true
                    return false
                }

				const invoicedetails_ids = this.bons.map(invoice_details => invoice_details.invoicedetails_id)

                const stats = await this.loadInvoiceBonsStat(invoicedetails_ids)

                await Common.asyncForEach(stats, async (stat) => {
                    this.stat.push({
                        tiers: stat.tiers,
                        total_ht: await this.priceFormat(stat.total_ht, 'EUR')
                    })
                })

                this.stats_loaded = true
            },
			async compareLoadingMethods() {
				const bons = await this.loadAllBonsv2(this.options)
                const bons_v2 = await this.formatBons(bons)

				if(this.bons.length != bons_v2.length) {
					this.sendErrorSlack(this.bons.length, bons_v2.length)
				}
			}
		},
		computed: {
			duedates: function(){
				return this.params_to_send.invoices
			}
		},
		watch: {
			'params_to_send.invoices':{
				handler(val){

					if(JSON.stringify(val).indexOf('duedates') > -1 && this.copy_duedate == null && this.recalcul_duedate)
					{
						this.copy_duedate = JSON.stringify(val)
					}

					if(this.copy_duedate != null)
					{
						if(JSON.stringify(val) != this.copy_duedate)
						{
							//Je passe une variable pour ne pas que cela recalcul les dates d'échéances 
							this.recalcul_duedate = false
						}
					}

				},
				deep: true
			},
		},
		components: {
			CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
			LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
			ErrorAlert: () => import('GroomyRoot/components/Alert/ErrorAlert'),
			LigneVierge: () => import('@/components/Invoice/LigneVierge'),
			Reorganize: () => import('@/components/Invoice/Reorganize'),
			ModalBons: () => import ('../Modals/ModalBons.vue')
		}
	}
</script>
